<template>
  <div
    class="flex flex-row items-center py-1"
    :class="{
      'justify-center': sizing === 'slogan',
    }"
  >
    <img
      data-not-lazy
      v-lazy-load :data-src="logoSrc"
      crossorigin="anonymous"
      alt=""
      :class="{
        'h-7 w-7 md:h-8 md:w-8': sizing === 'header',
        'h-10 w-10 md:h-12 md:w-12': sizing === 'footer',
        'h-10 w-10 md:h-40 md:w-40': sizing === 'slogan',
      }"
    />
    <div
      class="text-green-500 pl-1 font-bold"
      :class="{
        hidden: hideLabel,
        'text-lg md:text-xl pt-1': sizing === 'header',
        'text-2xl md:text-3xl pt-1': sizing === 'footer',
        'text-2xl md:text-8xl pt-1 md:pt-2': sizing === 'slogan',
      }"
    >
      {{ $t("branding.logo.part1") }}
    </div>
    <div
      class="text-primary-dark font-bold"
      :class="{
        hidden: hideLabel,
        'text-lg md:text-xl pt-1': sizing === 'header',
        'text-2xl md:text-3xl pt-1': sizing === 'footer',
        'text-2xl md:text-8xl pt-1 md:pt-2': sizing === 'slogan',
      }"
    >
      {{ $t("branding.logo.part2") }}
    </div>
  </div>
</template>

<script>
import JoszakiLogo from "~/assets/joszaki-head.svg";
import JoszakiLogoXmas from "~/assets/joszaki-head-xmas.svg";

import { generatePreloadLinks } from "~/helpers/files";

export default {
  name: "JoszakiLogo",
  props: {
    sizing: {
      type: String,
      default: "header",
      validator: (value) => ["header", "footer", "slogan"].includes(value),
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  head() {
    return {
      link: generatePreloadLinks([
        { as: "image", type: "image/svg+xml", href: this.logoSrc },
      ]),
    };
  },
  computed: {
    logoSrc() {
      if (new Date().getMonth() > 9) {
        return JoszakiLogoXmas;
      } else {
        return JoszakiLogo;
      }
    },
  },
};
</script>
