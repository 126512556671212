var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row items-center py-1",class:{
    'justify-center': _vm.sizing === 'slogan',
  }},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],class:{
      'h-7 w-7 md:h-8 md:w-8': _vm.sizing === 'header',
      'h-10 w-10 md:h-12 md:w-12': _vm.sizing === 'footer',
      'h-10 w-10 md:h-40 md:w-40': _vm.sizing === 'slogan',
    },attrs:{"data-not-lazy":"","data-src":_vm.logoSrc,"crossorigin":"anonymous","alt":""}}),_c('div',{staticClass:"text-green-500 pl-1 font-bold",class:{
      hidden: _vm.hideLabel,
      'text-lg md:text-xl pt-1': _vm.sizing === 'header',
      'text-2xl md:text-3xl pt-1': _vm.sizing === 'footer',
      'text-2xl md:text-8xl pt-1 md:pt-2': _vm.sizing === 'slogan',
    }},[_vm._v(" "+_vm._s(_vm.$t("branding.logo.part1"))+" ")]),_c('div',{staticClass:"text-primary-dark font-bold",class:{
      hidden: _vm.hideLabel,
      'text-lg md:text-xl pt-1': _vm.sizing === 'header',
      'text-2xl md:text-3xl pt-1': _vm.sizing === 'footer',
      'text-2xl md:text-8xl pt-1 md:pt-2': _vm.sizing === 'slogan',
    }},[_vm._v(" "+_vm._s(_vm.$t("branding.logo.part2"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }